// 地图覆盖物类工具类

// 绘制圆
export const DrawCircle = (points, BMap, styleOptions) => {
  const point = new BMap.Point(points[0], points[1])
  const radius = points[2]
  return new BMap.Circle(point, radius, styleOptions)
}

// 绘制矩形
export const DrawRectangle = (points, BMap, styleOptions) => {
  const pStart = new BMap.Point(points[0], points[1])
  const pEnd = new BMap.Point(points[2], points[3])
  return new BMap.Polygon([new BMap.Point(pStart.lng, pStart.lat), new BMap.Point(pEnd.lng, pStart.lat), new BMap.Point(pEnd.lng, pEnd.lat), new BMap.Point(pStart.lng, pEnd.lat)], styleOptions)
}

// 绘制多边形
export const DrawPolygon = (points, BMap, styleOptions) => {
  const n = Math.floor(points.length / 2)
  const pts = []
  for (let k = 0; k < n; k++) {
    pts.push(new BMap.Point(points[2 * k], points[2 * k + 1]))
  }
  return new BMap.Polygon(pts, styleOptions)
}

// 绘制线
export const DrawPolyline = (points, BMap, styleOptions) => {
  return new BMap.Polyline([new BMap.Point(points[0], points[1]), new BMap.Point(points[2], points[3])], styleOptions)
}
