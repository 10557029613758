<template>
  <vxe-modal
    v-model="ModalVisible"
    width="90%"
    height="96%"
    class="draw-map-zone"
    title="路段区域绘制"
    :show-zoom="true"
    resize
    :lock-view="false"
    :mask="false"
    :position="position"
    @close="handleCloseRoutePlanModal"
  >
    <div class="content">
      <div class="map">
        <div class="search">
          <a-input-search v-model="searchText" placeholder="请输入地址查找位置" enter-button @search="handleLocationSearch" @focus="handleFocus" />
        </div>

        <baidu-map class="map-page" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true">
          <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 135, height: 15 }" />
          <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 45, height: 15 }" />
          <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
          <!-- 两侧操作按钮 -->
          <bm-control class="map-control">
            <div class="map-tool" v-if="!readOnly">
              <a-button @click="handleDrawCircle">画圆</a-button>
              <a-button @click="handleDrawRectangle">画矩形</a-button>
              <a-button @click="handleDrawPolygon">画多边形</a-button>
              <a-button @click="handleDrawRoad">画路线</a-button>
              <!-- <a-button @click="doSetDistance">测距</a-button> -->
            </div>
          </bm-control>
          <!-- <bm-control class="draw-info">
        <div class="unit">单位：{{ unitBase === 1000 ? '公里' : '米' }}</div>
      </bm-control> -->
        </baidu-map>
      </div>
      <div class="search-result">
        <div class="city">当前城市：{{ mapInfo.cityName }}</div>

        <div class="search-result-info">
          {{ resultInfo }}
        </div>
        <div class="address-list" v-if="addressList.length > 0" id="addressList">
          <div
            class="address-list-item"
            :class="[temp.iwOpenIndex === index ? 'hover' : '']"
            v-for="(item, index) in addressList"
            :key="index"
            @click="handleToPoint(item)"
            @mouseover="handleMouseOver(index)"
            @mouseout="handleMouseOut(index)"
          >
            <div class="row">
              <span class="col1" :class="['mk-' + index]"> </span>
              <div class="col2">
                <div class="address-title">{{ item.title }}</div>
                <div class="address">{{ item.address }}</div>
                <div class="point">坐标：{{ item.point.lng.toFixed(4) + ',' + item.point.lat.toFixed(4) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="city-list" v-if="cityList.length > 0">
          <div class="city-list-item" @click="searchInthisCity(item.city)" v-for="(item, index) in cityList" :key="index">{{ item.city }}({{ item.num }})</div>
        </div>
        <a-pagination
          v-if="addressList.length > 0"
          class="ant-table-pagination"
          :simple="true"
          :current="pageNum"
          :total="total"
          :page-size="pageSize"
          :showTotal="(total) => `共 ${total} 条`"
          @change="changeSize"
        />
      </div>
    </div>
    <div class="map-bottom" :style="{ 'justify-content': readOnly ? 'flex-end' : 'space-between' }">
      <div v-if="!readOnly">
        <a-button @click="doClearMap">全部清除</a-button>
        <a-button @click="handleStop" style="margin-left: 10px">停止绘制</a-button>
      </div>
      <div>
        <a-button @click="ModalVisible = false" class="cancel">{{ readOnly ? '返回' : '取消' }}</a-button> <a-button v-if="!readOnly" @click="handleSave" type="primary">保存</a-button>
      </div>
    </div>
    <div class="search">
      <a-list size="small" bordered :data-source="cityList" v-if="setCityVisible" class="city">
        <a-list-item slot="renderItem" slot-scope="item" @click="setMapCity(item)">
          {{ item }}
        </a-list-item>
        <div slot="header">
          <button @click="setCityVisible = false">关闭</button>
        </div>
      </a-list>
      <a-list size="small" bordered :data-source="posArray" v-if="setAddressVisible" class="address">
        <a-list-item slot="renderItem" slot-scope="item" @click="setMapCenter(item.posId)">
          {{ item.posName }}
        </a-list-item>
        <div slot="header">
          <button @click="setAddressVisible = false">关闭</button>
        </div>
      </a-list>
      <!-- 分页 -->
    </div>

    <a-modal class="param" :title="paramTitle" :visible="setParamVisible" @ok="handleSetParamOk" @cancel="setParamVisible = false" :z-index="10000">
      <div>
        <a-form-model-item label="输入半径(米)" v-if="drawMode === 'circle'">
          <a-input-number v-model="radius" />
        </a-form-model-item>
        <a-form-model-item label="输入路宽(米)" v-if="drawMode === 'road'">
          <a-input-number v-model="roadWidth" />
        </a-form-model-item>
      </div>
    </a-modal>
  </vxe-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapState, mapGetters } from 'vuex'
import { DrawCircle, DrawPolygon, DrawPolyline, DrawRectangle } from '@/views/monitor/monitorComponents/mappage/MapOverlayHelper'
import { List } from 'ant-design-vue'
export default {
  components: {
    AList: List,
    AListItem: List.Item
  },
  data() {
    this.handleLocationSearch = debounce(this.handleLocationSearch, 1000)
    return {
      zoomNum: 12,
      temp: {
        pt: [],
        mk: [],
        iwOpenIndex: null
      },
      mapInfo: {
        cityName: '',
        cityCode: '',
        centerPoint: null
      },
      addressList: [],
      pageNum: 1,
      total: 0,
      pageSize: 3,
      searchText: '',
      ModalVisible: false,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      styleOptions: {
        strokeColor: 'red', // 边线颜色。
        fillColor: 'red', // 填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, // 边线的宽度，以像素为单位。
        strokeOpacity: 0.8, // 边线透明度，取值范围0 - 1。
        fillOpacity: 0.6, // 填充的透明度，取值范围0 - 1。
        strokeStyle: 'solid' // 边线的样式，solid或dashed。
      },
      paramTitle: '圆半径',
      setParamVisible: false,
      // 半径
      radius: 0,
      // 路宽
      roadWidth: 0,
      // 覆盖层
      overlayArray: [],
      // 文本标注覆盖层
      labelOverlayArray: [],
      // 坐标点数组
      pointArray: [],
      drawMode: '',
      posArray: [],
      setCityVisible: false,
      setAddressVisible: false,
      cityList: [],
      address: '',
      open: false,
      // 是否绘制多个区域
      drawManyZone: false,
      // 绘制区域列表
      drawZoneArray: [],
      // 是否只读
      readOnly: false
      // unitBase: 1
    }
  },
  computed: {
    ...mapState({
      mapTheme: (state) => state.user.mapTheme,
      mapStyleJson: (state) => state.user.mapStyleJson
    }),
    ...mapGetters(['userTenant'])
  },
  watch: {
    ModalVisible(newVal, oldVal) {
      if (!newVal) {
        console.log('资源释放')
        this.handleClear()
      }
    }
  },
  created() {
    // 几何工具
    this.addScript('//api.map.baidu.com/library/GeoUtils/1.2/src/GeoUtils_min.js')
  },
  methods: {
    handleMouseOver(g) {
      if (this.temp.iwOpenIndex === g) {
        return
      }
      var i = this.temp.mk[g]
      var h = i.getIcon()
      h.setImageOffset(new this.BMap.Size(0, -250 - g * 25))
      i.setIcon(h)
      i.setTop(true, 1000100)
      // Fe.G('no_' + g) ? (Fe.G('no_' + g).className = 'hover') : null
      this.temp.iwOpenIndex = g
    },
    handleMouseOut(g) {
      var i = this.temp.mk[g]
      // if (this.temp.iwOpenIndex === g) {
      //   i.setTop(true)
      //   return
      // }
      var h = i.getIcon()
      h.setImageOffset(new this.BMap.Size(0, -g * 25))
      i.setIcon(h)
      i.setTop(false)
      // Fe.G('no_' + g) ? (Fe.G('no_' + g).className = '') : null
      this.temp.iwOpenIndex = null
    },

    clearLastResult() {
      this.temp.mk.forEach((p) => {
        this.map.removeOverlay(p)
      })
      this.temp.pt = []
      this.temp.mk = []
    },
    addMarker(a) {
      const b = this.temp.pt[a]
      const d = new this.BMap.Icon('/images/markers.png', new this.BMap.Size(23, 25), {
        offset: new this.BMap.Size(10, 25),
        imageOffset: new this.BMap.Size(0, 0 - a * 25),
        infoWindowAnchor: new this.BMap.Size(12, 0)
      })
      const c = new this.BMap.Marker(b, {
        icon: d
      })
      this.map.addOverlay(c)
      this.temp.mk.push(c)
      return c
    },
    changeSize(current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.localSearch.gotoPage(this.pageNum)
    },
    handleFocus() {
      if (this.posArray.length > 0) {
        this.setAddressVisible = true
      }
    },
    setMapCenter(center) {
      this.center = center
      this.setAddressVisible = false
    },
    setMapCity(city) {
      this.mapSearch && this.mapSearch.setLocation(city)
      this.mapSearch && this.mapSearch.search(this.address)
      this.setCityVisible = false
    },
    handleSetCityOk() {
      this.setCityVisible = false
    },
    handleLocationSearch(value) {
      if (!value) {
        return
      }
      this.address = value
      this.beginsearch(this.localSearch)
    },
    beginsearch(b, a) {
      if (!a) {
        b.setLocation(this.map)
      }
      b.search(this.address)
    },

    handleLocationChange(value) {
      this.open = false
      this.center.lng = value.lng
      this.center.lat = value.lat
    },
    // 开启测距
    doSetDistance() {
      // this.drawingManager.close()
      // this.myDis.open()
    },
    // 绘制地图
    drawMap(form, drawManyZone, readOnly) {
      console.log('drawMap')
      this.initDrawMap()
      this.readOnly = readOnly
      this.drawManyZone = drawManyZone
      this.form = form
      // 绘制地图区域
      this.doDrawZone(form)
      this.ModalVisible = true
    },
    initDrawMap() {
      this.searchText = ''
      this.addressList = []
      this.cityList = []
      this.resultInfo = ''
      this.handleClear()
      this.doClearOverlay()
    },
    doDrawZone(form) {
      if (this.map) {
        const points = []
        this.drawZoneArray.length = 0
        if (this.drawManyZone) {
          if (form.geomArray && form.geomArray.length > 0) {
            form.geomArray.forEach((p) => {
              this.drawZoneArray.push({
                geomType: p.geomType,
                geomText: p.geomText
              })
            })
          }
        } else {
          if (form.geomType && form.geomText) {
            this.drawZoneArray.push({
              geomType: form.geomType,
              geomText: form.geomText
            })
          }
        }
        this.drawZoneArray.forEach((p) => {
          points.push(...this.drawArea(p.geomType, p.geomText))
        })
        // 设置地图视野
        // const zoomFactor = !this.drawManyZone && form.geomType === 1 ? -3 : 0
        setTimeout(() => {
          if (points.length > 0) {
            let zoomFactor = 0
            const len = points.length
            if (len === 1) {
              zoomFactor = -3
            } else if (len === 2) {
              zoomFactor = -2
            } else {
              zoomFactor = 0
            }
            this.map.setViewport(points, { zoomFactor: zoomFactor })
          } else {
            this.map.setViewport([new this.BMap.Point(this.center.lng, this.center.lat)], -3)
          }
        }, 100)

        // 先画折线再加载脚本，解决进来看不到折线，无法绘制
        this.loadScript()
      } else {
        setTimeout(() => {
          this.doDrawZone(form)
        }, 50)
      }
    },
    drawArea(geomType, geomText) {
      let pointArray = []
      const { BMap } = this
      // 绘制圆
      if (geomText) {
        pointArray = geomText.split(',')
      }
      const path = []
      if (geomType === 1) {
        this.radius = pointArray[2] * 1
        // 画圆
        const circle = DrawCircle(pointArray, BMap, this.styleOptions)
        this.overlayArray.push(circle)
        this.map.addOverlay(circle)
        path.push({
          lng: pointArray[0],
          lat: pointArray[1]
        })
        this.addCircleLabel(path[0], this.radius)
      } else if (geomType === 2) {
        // 画矩形
        const pStart = new BMap.Point(pointArray[0], pointArray[1])
        const pEnd = new BMap.Point(pointArray[2], pointArray[3])
        const rectangle = DrawRectangle(pointArray, BMap, this.styleOptions)
        this.overlayArray.push(rectangle)
        this.map.addOverlay(rectangle)

        path.push(pStart)
        path.push({
          lng: pEnd.lng,
          lat: pStart.lat
        })
        path.push(pEnd)
        path.push({
          lng: pStart.lng,
          lat: pEnd.lat
        })
        this.addRectangleLabel(path)
      } else if (geomType === 3) {
        const n = Math.floor(pointArray.length / 2)
        for (let k = 0; k < n; k++) {
          path.push({
            lng: pointArray[2 * k],
            lat: pointArray[2 * k + 1]
          })
        }
        const polygon = DrawPolygon(pointArray, BMap, this.styleOptions)
        this.overlayArray.push(polygon)
        this.map.addOverlay(polygon)
        this.addPolygonLabel(path)
      } else if (geomType === 4) {
        this.lineWidth = pointArray[4]
        const polyline = DrawPolyline(pointArray, BMap, this.styleOptions)
        this.overlayArray.push(polyline)
        this.map.addOverlay(polyline)
        const p1 = {
          lng: pointArray[0],
          lat: pointArray[1]
        }
        const p2 = {
          lng: pointArray[1],
          lat: pointArray[2]
        }
        path.push(p1)
        path.push(p2)
        this.addLineLabel(p1, p2)
      }
      return path
    },
    handleCloseRoutePlanModal() {
      this.ModalVisible = false
    },
    initDrawingManager() {
      if (window.BMapLib.DrawingManager) {
        this.drawingManager = new window.BMapLib.DrawingManager(this.map, {
          isOpen: false, // 是否开启绘制模式
          enableDrawingTool: false, // 是否显示工具栏
          drawingToolOptions: {
            anchor: window.BMAP_ANCHOR_TOP_RIGHT, // 位置
            offset: new this.BMap.Size(5, 5) // 偏离值
          },
          circleOptions: this.styleOptions, // 圆的样式
          polylineOptions: this.styleOptions, // 线的样式
          polygonOptions: this.styleOptions, // 多边形的样式
          rectangleOptions: this.styleOptions // 矩形的样式
        })
        this.drawingManager.addEventListener('overlaycomplete', this.handleOverlaycomplete)
      } else {
        setTimeout(() => {
          // this.myDis = new window.BMapLib.DistanceTool(this.map)
          this.initDrawingManager()
        }, 1000)
      }
    },
    initMap(MapObj) {
      this.BMap = MapObj.BMap
      this.map = MapObj.map
      const center = new this.BMap.Point(this.userTenant.lng, this.userTenant.lat)
      this.projection = new this.BMap.MercatorProjection()
      this.mapInfo.centerPoint = center
      // this.map.centerAndZoom(center, 12)
      this.map.addEventListener('load', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('moveend', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('dragend', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('zoomend', (b) => {
        this.getCurrentCityName()
      })

      this.myGeo = new this.BMap.Geocoder()
      // 获取当前租户配置的默认坐标
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat
      this.zoom = 18
      // this.map.addEventListener('click', (e) => {
      //   // 点中高亮及弹窗
      //   const isPointInCircle = window.BMapLib.GeoUtils.isPointInCircle(e.point, this.overlayArray[0])
      //   if (isPointInCircle) {
      //     this.overlayArray[0].setFillColor('blue')
      //   }
      // })
      this.initDrawingManager()
      this.initLocalSearch()
    },
    handleToPoint(item) {
      this.map.panTo(item.point)
    },
    searchInthisCity(a) {
      this.localSearch.setLocation(a)
      this.beginsearch(this.localSearch, 'cityList')
    },
    setCurrentMapInfo(c, d) {
      const a = this.mapInfo
      const b = this.map.getZoom()
      a.cityName = c
      a.cityCode = d
      a.centerPoint = this.map.getCenter()
      this.zoomNum = b
      // $('#curCity').html(c)
      // $('#ZoomNum').html(b)
      // this.mapSearch.setLocation(c)
    },
    getCurrentCityName() {
      var a = this.map.getZoom()
      var c
      var h = 10000
      if (a <= 7) {
        c = a
        this.setCurrentMapInfo('全国')
        return
      }
      const i = () => {
        var m = this.map.getBounds()
        var j = this.projection.lngLatToPoint(m.getSouthWest())
        var l = this.projection.lngLatToPoint(m.getNorthEast())
        var k = function (n) {
          return parseInt(n / 1000) * 1000
        }
        return k(j.x) + ',' + k(j.y) + ';' + k(l.x) + ',' + k(l.y)
      }
      const e = this.mapInfo.centerPoint
      const d = this.map.getCenter()
      const f = Math.sqrt((e.lng - d.lng) * (e.lng - d.lng) + (e.lat - d.lat) * (e.lat - d.lat))
      const g = () => {
        if (typeof _mapCenter === 'undefined') {
          return
        }
        var j = window._mapCenter
        var k = j.content
        if (!k) {
          return
        }
        this.setCurrentMapInfo(window._mapCenter.content.name, window._mapCenter.content.uid)
      }
      if (f > h || a !== c) {
        c = a
        const b = 'https://map.baidu.com/?newmap=1&qt=cen&b=' + i() + '&l=' + a
        this.scriptRequest(b, g, '_MAP_CENTER_', 'gbk')
      }
    },
    scriptRequest(url, echo, id, charset) {
      let script = null
      const isIe = /msie/i.test(window.navigator.userAgent)
      const scriptId = document.getElementById('_script_' + id)
      if (isIe && scriptId) {
        script = scriptId
      } else {
        if (scriptId) {
          scriptId.parentNode.removeChild(scriptId)
        }
        script = document.createElement('script')
        if (charset != null) {
          script.charset = charset
        }
        if (id != null && id !== '') {
          script.setAttribute('id', '_script_' + id)
        }
        script.setAttribute('type', 'text/javascript')
        document.body.appendChild(script)
      }
      var t = new Date()
      if (url.indexOf('?') > -1) {
        url += '&t=' + t.getTime()
      } else {
        url += '?t=' + t.getTime()
      }
      const _complete = () => {
        if (!script.readyState || script.readyState === 'loaded' || script.readyState === 'complete') {
          if (echo === 'null') {
          } else {
            if (typeof echo === 'function') {
              try {
                echo()
              } catch (e) {}
            } else {
              // eslint-disable-next-line no-eval
              window.eval(echo)
            }
          }
        }
      }
      if (isIe) {
        script.onreadystatechange = _complete
      } else {
        script.onload = _complete
      }
      script.setAttribute('src', url)
    },
    // 拾取坐标初始化
    initLocalSearch() {
      const d = {
        onSearchComplete: (w) => {
          this.clearLastResult()
          this.addressList = []
          this.cityList = []
          if (this.localSearch.getStatus() === window.BMAP_STATUS_SUCCESS) {
            const l = w.getCurrentNumPois()
            const k = w.getCurrentNumPois()
            const e = w.getNumPois()
            // let p = ''

            if (l === 1 && typeof w.getPoi(0).city === 'undefined') {
              // let f = 4
              // if (b && b._json && b._json.content && b._json.content.length == 2) {
              if (w && w.getNumPois() === 2) {
                // f = w.getPoi(1)
                this.resultInfo = w.keyword
              } else {
                if (w && w.city) {
                  this.resultInfo = '已切换至' + w.city
                }
              }
              this.map.centerAndZoom(w.getPoi(0).point, 12)
            } else {
              const pts = []
              this.addressList = []
              for (let z = 0; z < l; z++) {
                const h = w.getPoi(z)
                const q = h.title
                const u = h.address
                const v = h.point
                pts.push(v)
                // const A = v.lng + ',' + v.lat
                let x = q
                const j = h.type
                let t = '地址'
                if (x.length > 20) {
                  x = x.substring(0, 17) + '...'
                }
                if (j === 1) {
                  t = '途径公交车'
                }
                if (j === 3) {
                  t = '途径地铁'
                }
                this.addressList.push({
                  title: x,
                  address: t + '：' + u,
                  point: v
                })

                this.temp.pt.push(v)
                this.addMarker(z)
              }
              // 重置地图视野
              this.map.setViewport(pts)
              // 下一页
              this.pageNum = w.getPageIndex()
              // 总记录数
              this.total = e
              // 页大小
              this.pageSize = k
              this.resultInfo = '共找到' + this.total + '条结果'
              // marker绑定鼠标事件
              this.bindEvent()
              setTimeout(() => {
                const el = document.getElementById('addressList')
                if (el) {
                  el.scrollTop = 0
                }
              }, 0)
            }
          } else {
            // var y = ''
            this.totalCount = 0

            if (w.city !== '全国') {
              this.resultInfo = '在' + w.city + '及全国没有找到相关的地点。'
            } else {
              this.resultInfo = '在全国没有找到相关的地点。'
            }
            if (w.getCityList().length > 0) {
              if (w.city !== '全国') {
                this.resultInfo = '在<b>' + w.city + '</b>没有找到相关的地点。'
              } else {
                this.resultInfo = '在以下城市有结果，请您选择:'
              }
              const g = w.getCityList()
              this.cityList = []
              if (g.length > 0) {
                this.resultInfo = '在以下城市找到结果，请选择城市:'

                for (let z = 0; z < g.length; z++) {
                  this.cityList.push({
                    city: g[z].city,
                    num: g[z].numResults
                  })
                }
                console.log('cityList', JSON.stringify(this.cityList))
              }
            }
          }
        }
      }
      this.localSearch = new this.BMap.LocalSearch(this.map, d)
    },
    bindEvent() {
      const d = this.temp.mk
      for (let f = 0; f < d.length; f++) {
        const h = f
        d[h].addEventListener('click', () => {})
        d[h].addEventListener('mouseover', () => {
          this.handleMouseOver(h)
        })
        d[h].addEventListener('mouseout', () => {
          this.handleMouseOut(h)
        })
      }
    },

    addScript(url) {
      const $script = document.createElement('script')
      global.document.body.appendChild($script)
      $script.src = url
    },
    loadScript() {
      // 地图工具类
      if (!window.BMapLib.DrawingManager) {
        console.log('加载绘制工具脚本')
        this.addScript('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
      }
      // 测距
      // this.addScript('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
    },
    // 绘制圆，可以指定半径
    handleDrawCircle() {
      this.setDrawingMode(window.BMAP_DRAWING_CIRCLE)
      this.paramTitle = '设定圆半径'
      this.drawMode = 'circle'
      this.setParamVisible = true
      this.drawingManager.open()
    },
    handleDrawRectangle() {
      this.setDrawingMode(window.BMAP_DRAWING_RECTANGLE)
      this.drawingManager.open()
    },
    handleDrawPolygon() {
      this.setDrawingMode(window.BMAP_DRAWING_POLYGON)
      this.drawingManager.open()
    },
    handleDrawRoad() {
      this.setDrawingMode(window.BMAP_DRAWING_POLYLINE)
      this.paramTitle = '设置路宽'
      this.drawMode = 'road'
      this.setParamVisible = true
      this.drawingManager.open()
    },
    handleSetParamOk() {
      this.setParamVisible = false
    },
    addCircleLabel(point, radius) {
      this.doClearMapLabel()
      const text = radius >= 1000 ? '半径:' + (radius / 1000).toFixed(1) + '公里' : '半径:' + radius.toFixed(0) + '米'
      const pt = new this.BMap.Point(point.lng, point.lat)
      const label = new this.BMap.Label(text, {
        position: pt
      })
      this.map.addOverlay(label)
      this.labelOverlayArray.push(label)
    },
    addLineLabel(p1, p2, offsetY, position) {
      const len = this.parseLineWidth(p1, p2)
      const point = position ? new this.BMap.Point(position.lng, position.lat) : new this.BMap.Point(p2.lng, p2.lat)
      const label = new this.BMap.Label(len + '', {
        position: point,
        offset: new this.BMap.Size(5, offsetY) // 设置文本偏移量
      })
      this.map.addOverlay(label)
      this.labelOverlayArray.push(label)
    },
    // 添加起点Label
    addStartLabel(start) {
      const point = new this.BMap.Point(start.lng, start.lat)
      const label = new this.BMap.Label('起点', {
        position: point,
        offset: new this.BMap.Size(5, -25) // 设置文本偏移量
      })
      this.map.addOverlay(label)
      this.labelOverlayArray.push(label)
    },
    // 获取线宽
    parseLineWidth(p1, p2) {
      const len = this.getWidthByPoints([p1, p2])
      return len >= 1000 ? (len / 1000).toFixed(1) + '公里' : len.toFixed(0) + '米'
    },
    // 基于坐标集计算面积
    addAreaLabel(path, index1, index2) {
      // 计算面积
      const area = this.getAreaByPoints(path).toFixed(0)
      const point = this.getCenterPoint([path[index1], path[index2]])
      const label = new this.BMap.Label('面积:' + area, {
        position: point,
        offset: new this.BMap.Size(5, 5) // 设置文本偏移量
      })
      this.map.addOverlay(label)
      this.labelOverlayArray.push(label)
    },
    // 矩形添加Label文本
    addRectangleLabel(path) {
      this.doClearMapLabel()
      this.addStartLabel(path[0])
      this.addLineLabel(path[0], path[1], -25)
      this.addLineLabel(path[1], path[2], 5)
    },
    // 多边形添加Label
    addPolygonLabel(path) {
      this.doClearMapLabel()
      const n = Math.floor(path.length / 2)
      // 多边形
      this.addStartLabel(path[0])
      for (let k = 0; k < n; k++) {
        this.addLineLabel(path[2 * k], path[2 * k + 1], 5)
        if (2 * (k + 1) < path.length) {
          this.addLineLabel(path[2 * k + 1], path[2 * (k + 1)], 5)
        }
      }
      // 画第一点与最后一点
      this.addLineLabel(path[0], path[path.length - 1], 5, path[0])
    },
    // 图形绘制完成回调事件
    handleOverlaycomplete(e) {
      console.log('handleOverlaycomplete')
      const drawingType = this.drawingManager.getDrawingMode()
      let isValid = false
      const labelArray = []
      let pointArray = []
      let geomType = 0
      // 画圆
      if (drawingType === window.BMAP_DRAWING_CIRCLE) {
        geomType = 1
        if (e.overlay.getRadius() < 1) {
          return
        }
        if (this.radius > 0) {
          e.overlay.setRadius(this.radius)
        } else {
          this.radius = (e.overlay.getRadius() * 100) / 100
        }
        // 坐标点呢
        const point = e.overlay.getCenter()
        pointArray = []
        pointArray.push(point.lng)
        pointArray.push(point.lat)
        pointArray.push(this.radius)
        isValid = true
        this.addCircleLabel(point, this.radius)
      } else if (drawingType === window.BMAP_DRAWING_RECTANGLE) {
        geomType = 2
        const path = e.overlay.getPath()
        console.log('path', path)
        if (path.length === 4) {
          pointArray = []
          pointArray.push(path[0].lng)
          pointArray.push(path[0].lat)
          pointArray.push(path[2].lng)
          pointArray.push(path[2].lat)
          isValid = this.isPloygon(path)
          if (isValid) {
            this.addRectangleLabel(path)
          }
        }
      } else if (drawingType === window.BMAP_DRAWING_POLYGON) {
        geomType = 3
        const path = e.overlay.getPath()
        pointArray = []
        path.forEach((p) => {
          pointArray.push(p.lng)
          pointArray.push(p.lat)
        })
        isValid = this.isPloygon(path)
        if (isValid) {
          this.addPolygonLabel(path)
        }
      } else if (drawingType === window.BMAP_DRAWING_POLYLINE) {
        geomType = 4
        const path = e.overlay.getPath()
        pointArray = []
        if (path.length >= 2) {
          pointArray.push(path[0].lng)
          pointArray.push(path[0].lat)
          pointArray.push(path[1].lng)
          pointArray.push(path[1].lat)
          pointArray.push(this.roadWidth)
        }
        isValid = this.isPolyline(path)

        if (isValid) {
          this.doClearMapLabel()
          this.addLineLabel(path[0], path[1])
        }
      }
      if (isValid) {
        if (!this.drawManyZone) {
          // 绘制单一区域，则删除之前的
          this.doClearOverlay()
          this.drawZoneArray.length = 0
        }
        this.overlayArray.push(e.overlay)
        labelArray.forEach((p) => {
          this.overlayArray.push(p)
        })
        labelArray.length = 0
        if (geomType && pointArray.length > 0) {
          this.drawZoneArray.push({
            geomType: geomType,
            geomText: pointArray.join(',')
          })
        }
      } else {
        this.map.removeOverlay(e.overlay)
      }
      console.log('pointArray', pointArray.join(','))
    },
    // 设置当前的绘制模式，参数DrawingType，为5个可选常量:
    // BMAP_DRAWING_MARKER 画点
    // BMAP_DRAWING_CIRCLE 画圆
    // BMAP_DRAWING_POLYLINE 画线
    // BMAP_DRAWING_POLYGON 画多边形
    // BMAP_DRAWING_RECTANGLE 画矩形
    setDrawingMode(DrawingType) {
      this.drawingManager.setDrawingMode(DrawingType)
    },
    doClearOverlay() {
      if (this.map && this.overlayArray.length > 0) {
        this.overlayArray.forEach((p) => {
          this.map.removeOverlay(p)
        })
        this.overlayArray.length = 0
      }
    },
    doClearMapLabel(force) {
      if (!this.drawManyZone || force) {
        if (this.map && this.labelOverlayArray.length > 0) {
          this.labelOverlayArray.forEach((p) => {
            this.map.removeOverlay(p)
          })
          this.labelOverlayArray.length = 0
        }
      }
    },
    handleClear() {
      this.doClearOverlay()
      this.doClearMapLabel(true)
      this.drawZoneArray.length = 0
    },
    // 判断是否为折线
    isPolyline(path) {
      return this.getWidthByPoints(path) > 1
    },
    // 基于两个坐标计算经纬度
    getWidthByPoints(path) {
      const pts = []
      path.forEach((p) => {
        pts.push(new this.BMap.Point(p.lng, p.lat))
      })
      return window.BMapLib.GeoUtils.getPolylineDistance(pts)
    },
    // 获取多个坐标点包围的面积
    getAreaByPoints(path) {
      const pts = []
      path.forEach((p) => {
        pts.push(new this.BMap.Point(p.lng, p.lat))
      })
      const ply = new this.BMap.Polygon(pts)
      return window.BMapLib.GeoUtils.getPolygonArea(ply)
    },
    // 是否为多边形
    isPloygon(path) {
      return this.getAreaByPoints(path) > 1
    },
    // 保存
    handleSave() {
      console.log('handleSave')
      if (this.drawZoneArray.length === 0) {
        this.$warningEx('请先在地图上绘制路段范围！')
        return
      }
      this.drawingManager && this.drawingManager.close()
      this.$emit(
        'ok',
        this.drawManyZone
          ? [...this.drawZoneArray]
          : {
              geomType: this.drawZoneArray[0].geomType,
              geomText: this.drawZoneArray[0].geomText
            }
      )
      this.ModalVisible = false
    },
    // 获取两个节点中间节点
    getCenterPoint(pointArr) {
      var lng1 = parseFloat(pointArr[0].lng)
      var lat1 = parseFloat(pointArr[0].lat)
      var lng2 = parseFloat(pointArr[1].lng)
      var lat2 = parseFloat(pointArr[1].lat)
      var lngca = (Math.max(lng1, lng2) - Math.min(lng1, lng2)) / 2
      var latca = (Math.max(lat1, lat2) - Math.min(lat1, lat2)) / 2
      var lngcenter = Math.min(lng1, lng2) + lngca
      var latcenter = Math.min(lat1, lat2) + latca
      var pointcenter = new this.BMap.Point(lngcenter, latcenter)
      return pointcenter
    },
    doClearMap() {
      this.$confirm({
        title: '确认全部清除吗?',
        onOk: () => {
          this.handleClear()
        },
        onCancel() {}
      })
    },
    handleStop() {
      this.drawingManager.close()
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 43px !important;
}
.content {
  height: 92% !important;
  display: flex;
  .search-result {
    flex: 0 0 200px;
    .city {
      padding: 0 10px;
      color: #00c;
    }
    .search-result-info {
      padding: 0 10px;
    }
    .address-list {
      padding: 10px;
      height: 86%;
      overflow-y: auto;
      font-size: 12px;
      .address-list-item {
        cursor: pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        .address-title {
          color: #00c;
        }
        border-bottom: 1px solid #ececec;

        .row {
          position: relative;
          display: flex;
          .col2 {
            flex: 1;
            padding: 2px 10px 2px 3px;
            margin-left: 22px;
          }
          span {
            cursor: pointer;
            display: block;
            width: 19px;
            height: 25px;
            background: url(/images/markers.png) no-repeat 0 0;
            position: absolute;
            top: 3px;
            left: 0;
          }
          .mk-0 {
            background-position: -23px 0;
          }
          .mk-1 {
            background-position: -23px -25px;
          }
          .mk-2 {
            background-position: -23px -50px;
          }
          .mk-3 {
            background-position: -23px -75px;
          }
          .mk-4 {
            background-position: -23px -100px;
          }
          .mk-5 {
            background-position: -23px -125px;
          }
          .mk-6 {
            background-position: -23px -150px;
          }
          .mk-7 {
            background-position: -23px -175px;
          }
          .mk-8 {
            background-position: -23px -200px;
          }
          .mk-9 {
            background-position: -23px -225px;
          }
        }
        &.hover {
          background: #f0f0f0;
        }
      }
    }
    .city-list {
      padding: 10px;
      height: 92%;
      overflow-y: auto;
      .city-list-item {
        cursor: pointer;
        color: #00c;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ececec;
      }
    }
  }
  .map {
    flex: 1;
  }
}
.map-control {
  position: relative !important;
}
.map-tool {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #666;
  padding: 0;
  .ant-btn {
    margin-right: 0;
    border-radius: 0;
  }
}
.param {
  .ant-input-number {
    width: 100%;
  }
}
.map-page {
  height: 100% !important;
  .draw-info {
    position: relative !important;
    .unit {
      position: absolute;
      top: 0;
      left: 50%;
      width: 200px;
      height: 25px;
      transform: translateX(-50%);
      background: white;
      text-align: center;
    }
  }
}
.map-bottom {
  height: 7% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  .cancel {
    margin-right: 8px;
  }
}
.draw-map-zone {
  .ant-list-header {
    padding: 5px !important;
    text-align: right;
  }
}
</style>
<style lang="less" scoped>
.search {
  width: 300px;
  height: auto !important;
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 15px;
  .ant-select {
    width: 100%;
  }
  .city,
  .address {
    z-index: 101;
    background-color: #fff;
    height: 300px;
    overflow: auto;
  }
}
</style>
